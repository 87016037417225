
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CbxVariablesModule } from '../../store/modules/CbxVariablesModule';
import ProductDetailsModule from '../../store/modules/ProductDetailsModule';

@Component({
    name: 'DetailsColopConfiguratorFrame',
    components: {},
})
export default class DetailsColopConfiguratorFrame extends Vue {
    @Prop({ type: String, required: false }) private selectedTemplate: string;

    private checkoutMode: string = "direct";
    private producttype: string = "stamps";
    private mediaId: string = "nydan";
    private language: string = "dk_DK";

    get itemnumber(): string {
        if (ProductDetailsModule.VARIANTS?.length > 0) return ProductDetailsModule.SELECTED_VARIANT?.sku;
        else return ProductDetailsModule.PRODUCT?.id ?? '';
    }

    get name(): string {
        if (ProductDetailsModule.VARIANTS?.length > 0) return ProductDetailsModule.SELECTED_VARIANT?.name;
        else return ProductDetailsModule.PRODUCT?.name ?? '';
    }

    get frameSrc(): string {
        const data = new FormData();
        data.append('input[return_url]', `${window.location.protocol + '//' + window.location.host + window.location.pathname}?colop_callback=${CbxVariablesModule.VARIABLES.cartId}`);
        data.append('input[checkoutMode]', this.checkoutMode);
        data.append('input[producttype]', this.producttype);
        data.append('input[media_id]', this.mediaId);
        data.append('input[language]', this.language);
        data.append('input[templates]', this.templates);
        if (this.itemnumber.length > 0)
        {
            data.append('input[product_name]', this.name);
            data.append('input[available_padColors_creator]', this.availablePadColorsCreator);
            data.append('input[cartValues][product_id]', this.itemnumber);
        }
        // @ts-ignore
        return `https://nydan-shop.colop.com/?` + new URLSearchParams(data).toString();
    }

    get templates(): string {
        if (this.selectedTemplate)
            return this.selectedTemplate;

        return ProductDetailsModule.COLOP_CONFIGURATION?.templates?.map((t: string) => t.indexOf(".cbt") > 0 ? t.substring(0, t.indexOf(".cbt")) : t)?.join(",") ?? "";
    }
        
    get availablePadColorsCreator(): string {
        return ProductDetailsModule.COLOP_CONFIGURATION?.colors?.join(";") ?? "";
    }
}
