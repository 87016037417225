
import { Component, Vue, Watch } from 'vue-property-decorator';
import TrackingService from '../../services/TrackingService';
import CartModule from '../../store/modules/CartModule';
import { CbxVariablesModule } from '../../store/modules/CbxVariablesModule';
import ProductDetailsModule from '../../store/modules/ProductDetailsModule';
import DetailsColopConfiguratorFrame from './DetailsColopConfiguratorFrame.vue';

@Component({
    name: 'DetailsColopConfigurator',
    components: {
        DetailsColopConfiguratorFrame,
    },
})
export default class DetailsColopConfigurator extends Vue {
    private modalOpen: boolean = false;
    private trackingService: TrackingService = new TrackingService();

    get translationsReady(): boolean {
        return this.$root.$data.translationsLoaded === true;
    }

    get itemnumber(): string {
        if (ProductDetailsModule.VARIANTS?.length > 0) return ProductDetailsModule.SELECTED_VARIANT?.sku;
        else return ProductDetailsModule.PRODUCT?.id ?? '';
    }

    private async modalShow() {
        this.modalOpen = true;
    }

    private async modalHide() {
        this.modalOpen = false;
    }

    private async created() {
        await ProductDetailsModule.GET_COLOP_CONFIGURATION(this.itemnumber);

        const urlParams = new URLSearchParams(window.location.search);

        if (urlParams.get("addedtocart"))
        {
            const productid = urlParams.get("product");
            const variantid = urlParams.get("variant");
            const itemFromLookup = CartModule.CART_LOOKUP[`${productid}###${variantid ?? ''}`]

            // track add to cart
            this.trackingService.trackAddToCart(itemFromLookup);

            if (CbxVariablesModule.VARIABLES.enableAddedToCartModal) {
                CartModule.SET_ADD_TO_CART_MODAL({
                    show: true,
                    productId: productid,
                    variantId: variantid
                });
            } else {
                CartModule.SET_IS_OPEN(true);
            }

            window.history.replaceState({}, '', window.location.protocol + '//' + window.location.host + window.location.pathname);
        }
    }

    @Watch("itemnumber")
    private async onItemNumberChange() {
        await ProductDetailsModule.GET_COLOP_CONFIGURATION(this.itemnumber);
    }
}
