
import { Component, Vue } from 'vue-property-decorator';
import ProductDetailsModule from '../../store/modules/ProductDetailsModule';
import DetailsColopConfiguratorFrame from './DetailsColopConfiguratorFrame.vue';

@Component({
    name: 'DetailsColopTemplateList',
    components: {
        DetailsColopConfiguratorFrame,
    },
})
export default class DetailsColopTemplateList extends Vue {
    private modalOpen: boolean = false;
    private selectedTemplate: string = null;

    get translationsReady(): boolean {
        return this.$root.$data.translationsLoaded === true;
    }

    get productName() {
        if (ProductDetailsModule.VARIANTS?.length > 0) return ProductDetailsModule.SELECTED_VARIANT?.name;
        else return ProductDetailsModule.PRODUCT?.name ?? '';
    }

    get templates(): string[] {
        return ProductDetailsModule.COLOP_CONFIGURATION?.templates?.map((t: string) => t.indexOf(".cbt") > 0 ? t.substring(0, t.indexOf(".cbt")) : t) ?? [];
    }

    private async modalShow(template: string) {
        this.selectedTemplate = template;
        this.modalOpen = true;
    }

    private async modalHide() {
        this.modalOpen = false;
        this.selectedTemplate = null;
    }
}
